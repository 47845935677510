import React, { useRef, useEffect, useCallback } from 'react';
import ClipboardJS from 'clipboard';
import { Input, Button, message, Modal } from 'antd';
import { WechatOutlined, MoneyCollectOutlined, GiftOutlined, SortAscendingOutlined, TranslationOutlined, ContainerOutlined } from '@ant-design/icons';
import './components.css';
import { autoImport } from 'node-rsa/src/formats/pkcs1';

const EnglishPolish = 1;
const ChinesePolish = 2;
const ScientificChineseToEnglish = 3;
const ScientificEnglishToChinese = 4;

export function FunctionModal(props) {
    const { open, onCancel, setFunctionType } = props;
    return (<Modal
        open={open}
        onCancel={onCancel}
        closable={false}
        footer={null}
    >
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            margin: '0 auto',
        }}>
            <FuntionButton
                buttonText="普通模式"
                onClick={() => {
                    setFunctionType(0)
                    onCancel()
                }}
                icon={<WechatOutlined />} />
            <FuntionButton
                buttonText="论文中译英"
                onClick={() => {
                    setFunctionType(ScientificChineseToEnglish);
                    onCancel();
                }}
                icon={<TranslationOutlined />} />
            <FuntionButton
                buttonText="论文英译中"
                onClick={() => {
                    setFunctionType(ScientificEnglishToChinese);
                    onCancel();
                }}
                icon={<TranslationOutlined />} />
            <FuntionButton
                buttonText="英文论文润色"
                onClick={() => {
                    setFunctionType(EnglishPolish);
                    onCancel();
                }
                }
                icon={<SortAscendingOutlined />} />
            <FuntionButton
                buttonText="中文论文润色"
                onClick={() => {
                    setFunctionType(ChinesePolish);
                    onCancel();
                }}
                icon={<ContainerOutlined />} />


        </div>
    </Modal >);

}

function FuntionButton(props) {
    const { buttonText, onClick = () => { }, icon } = props;
    return (
        <div className='function-button' onClick={onClick}>
            {icon}<span style={{ 'marginLeft': '5px' }}>{buttonText}</span>
        </div>
    );
}